@tailwind base;
@tailwind components;
@tailwind utilities;

/* html,
body {
  @apply h-full  bg-white;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edebdb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Focus-in-expand animation */
@keyframes focusInExpand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
    transform: scale(1);
  }
}

.focus-in-expand {
  display: inline-block;
  animation: focusInExpand 1.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

/* Scroll-up animation */
@keyframes scrollUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.scroll-up {
  animation: scrollUp 2s ease forwards;
}

/* Prevent shifting of first text by setting a minimum height */
.intro-container {
  display: inline-block;
  text-align: center;
  position: relative;
  min-height: 80px; /* Adjust height as needed to fit the line and subtext */
  width: 100%;
}

.hidden {
  visibility: hidden; /* Hide the line and subtext initially */
  height: 0;
}

.line-container {
  margin-top: 8px; /* Adjust as needed to position the line properly */
}

.subtext-container {
  margin-top: 20px;
  visibility: hidden; /* Initially hide the subtext */
}

/* Animation for main content to make it scroll up when intro is hidden */
.main-content {
  opacity: 0;
  animation: scrollUp 2s ease forwards;
  position: relative;
  z-index: 5;
}

.main-content.scroll-up {
  animation: scrollUp 2s ease-out 1s forwards;
}

/* Fade-in effect for the main content */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease forwards; /* Adjust timing as necessary */
}
