.animated-underline {
  position: relative;
  color: #333;
  cursor: pointer;
  text-decoration: none;
  display: inline-block; /* Ensure only text width is considered */
  margin: 0; /* Remove default spacing */
  letter-spacing: 3px;
}

.animated-underline + .animated-underline {
  margin-top: 8px; /* Add spacing between lines if needed */
}

.animated-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust underline position */
  width: 100%; /* Automatically matches text width */
  height: 1px; /* Underline thickness */
  background-color: #333; /* Underline color */
  transform: scaleX(0); /* Initially hidden */
  transform-origin: left; /* Expand from the left */
  transition: transform 0.5s ease;
}

.animated-underline:hover::after {
  transform: scaleX(1); /* Expand underline */
}

.animated-underline:not(:hover)::after {
  transform: scaleX(0); /* Retract underline */
  transform-origin: right; /* Expand from the left */
}
