.form-container {
  /* background-color: #f4f4f4; */
  padding: 20px;
  border-radius: 8px;
  /* Ensure the container is responsive */
  max-width: 100%;
  width: 100%;
  box-sizing: border-box; /* Prevent width overflow */
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  /* text-align: center; */
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto; /* Center the form horizontally */
}

.input-group {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
  font-weight: 500;
}

.input,
.textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.textarea {
  min-height: 100px;
}

.submit-button {
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
