.swiper-pagination-bullet {
  /* background: rgba(128, 124, 124, 0.6); */
  background: rgb(245, 222, 179, 0.8);
  width: 12px;
  height: 12px;
  margin: 0 4px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: white;
  opacity: 1;
}

.swiper-pagination {
  justify-content: flex-start; /* Align bullets to the start */
  display: flex;
  margin-left: 32px;
  margin-bottom: 25px;
}
